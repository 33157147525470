export const screenOnly = {
  "@media print": {
    display: "none",
  },
};

export const printOnly = {
  "@media screen": {
    display: "none",
  },
};
